import { Component, OnInit } from '@angular/core';
import { GLOBAL } from './../../../services/global';
import { ClientesService } from 'src/app/services/clientes/clientes.service';
import { UsuariosService } from 'src/app/services/usuarios/usuarios.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { LoadingEfectComponent } from 'src/app/Layout/loading-efect/loading-efect.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-alerta-contrato',
  templateUrl: './alerta-contrato.component.html',
  styleUrls: ['./alerta-contrato.component.css']
})
export class AlertaContratoComponent implements OnInit {
  loading = new LoadingEfectComponent();
  searchAsistencia;
  public catalog:any[];
  public tiposContratos:any[];
  public obj:any = {
    EmpresaID: 0,
    Nombre: "",
    StatusContrato: 0,
    StatusNombre: "",
    TipoContratoID: 0,
    Prioridad: 0,
    NivelSeguridad: 0,
    NombreTC: "",
    UrlContrato: ""
  };
  public contr:any = {};
  public visit:any = {};
  uploadedFiles: Array<File>;

  //Modales
  public accionStatus1Modal:boolean = false;
  public accionStatus2Modal:boolean = false;
  public accionStatus3Modal:boolean = false;
  public accionStatus4Modal:boolean = false;
  public accionStatus1ExtModal:boolean = false;
  public accionStatus2ExtModal:boolean = false;

  identity = JSON.parse(localStorage.getItem('identity_user'));

  constructor(
    private _service:ClientesService,
    private _serviceUsuario:UsuariosService,
    public router: Router,
  ) { }

  ngOnInit() {
    this.getRegistros();
    this.getTipoContrato();
    this.registrarLog();
  }
  
  public registrarLog(){
    let clase = 'Alertas Pendientes Contratos';
    let obj = {
      //Controller: obj.Controller,
      Class: clase,
      //Table: 'ItemEmpresa',
      //ID: this.id,
      Message: 'ACCESO'
    }
    this._serviceUsuario.registrarLog(obj);
  }

  public getRegistros(){
    this.loading.onLoadingRequest();
    this._service.getAlertaContrato()
    .then(response=>{
      this.loading.onDoneRequest();
      if(response.status == 200){
        this.catalog = response.content;
        console.log(this.catalog);
      }else if(response.status == 500){
        Swal.fire("Error",response.message,"error");
      }
    })
    .catch(error=>{
      this.loading.onDoneRequest();
      console.log(error.toString());
    });
  }

  public getTipoContrato(){    
    this.loading.onLoadingRequest();
    this._service.getTipoContrato()
    .then(response=>{
      this.loading.onDoneRequest();
      if(response.status == 200){
        if(response.content != null && response.content.length > 0){
          this.tiposContratos = response.content;
        }
      }else if(response.status == 500){
        Swal.fire("Error",response.message,"error");
      }
    })
    .catch(error=>{
      this.loading.onDoneRequest();
      console.log(error.toString());
    });
  }

  public accionStatus1(open : boolean, EmpresaID: number, TipoServicio: number){
    this.obj.TipoContratoID = "";
    this.obj.EmpresaID = EmpresaID;
    this.obj.StatusContrato = 1;
    this.obj.Prioridad = 3;
    this.obj.NivelSeguridad = 3;
    this.obj.TipoServicio = TipoServicio;
    if(open){     
      $(".container").css('opacity',0.5);
      $("#sidebar-wrapper").css('opacity',0.5);
      $(".modal-title").html("SELECCIONAR TIPO DE CONTRATO");
    }else{      
      $(".container").css('opacity',1); 
      $("#sidebar-wrapper").css('opacity',1);      
    }
    this.accionStatus1Modal = open;
  }

  public accionStatus1Ext(open : boolean, EmpresaID: number){
    this.obj.TipoContratoID = "";
    this.obj.EmpresaID = EmpresaID;
    this.obj.StatusContrato = 1;
    this.obj.UrlContrato = "";
    if(open){     
      $(".container").css('opacity',0.5);
      $("#sidebar-wrapper").css('opacity',0.5);
      $(".modal-title").html("Alta y Cambio de Datos de Proveedores de DHL");
    }else{      
      $(".container").css('opacity',1); 
      $("#sidebar-wrapper").css('opacity',1);      
    }
    this.accionStatus1ExtModal = open;
  }

  public accionStatus2Ext(open : boolean, ContratoExtranjeraID: number){
    this.obj.StatusContrato = 3;
    this.contr.Comentario = "";
    this.contr.ContratoSolicitudID = ContratoExtranjeraID;
    if(open){     
      $(".container").css('opacity',0.5);
      $("#sidebar-wrapper").css('opacity',0.5);
      $(".modal-title").html("Autorizar Alta y Cambio de Datos de Proveedores de DHL");
    }else{      
      $(".container").css('opacity',1); 
      $("#sidebar-wrapper").css('opacity',1);      
    }
    this.accionStatus2ExtModal = open;
  }

  public accionStatus2(open : boolean, EmpresaID: number, TipoServicio: number){
    this.obj.EmpresaID = EmpresaID;
    this.obj.UrlContrato = "";
    this.obj.StatusContrato = 21;
    this.obj.TipoServicio = TipoServicio;
    if(open){     
      $(".container").css('opacity',0.5);
      $("#sidebar-wrapper").css('opacity',0.5);
      $(".modal-title").html("Cargar Contrato");
    }else{      
      $(".container").css('opacity',1); 
      $("#sidebar-wrapper").css('opacity',1);      
    }
    this.accionStatus2Modal = open;
  }

  public accionStatus3(open : boolean, ContratoSolicitudID: number, TipoServicio: number){
    this.obj.StatusContrato = 3;
    this.contr.Comentario = "";
    this.contr.ContratoSolicitudID = ContratoSolicitudID;
    this.obj.TipoServicio = TipoServicio;
    if(open){     
      $(".container").css('opacity',0.5);
      $("#sidebar-wrapper").css('opacity',0.5);
      $(".modal-title").html("Autorizar Contrato");
    }else{      
      $(".container").css('opacity',1); 
      $("#sidebar-wrapper").css('opacity',1);      
    }
    this.accionStatus3Modal = open;
  }

  public updateStatus1(){
    if(this.obj.StatusContrato == 21 && this.obj.UrlContrato == ""){
      Swal.fire('Atención', "Debe cargar un contrato para poder continuar.", 'warning');
      return false;
    }
    else if(this.obj.StatusContrato == 21 && this.obj.TipoServicio == 1){
      this.updateStatusEXT();
    }else{
      this.loading.onLoadingRequest();
      this._service.updateStatus1(this.obj)
      .then(response=>{
        this.loading.onDoneRequest();
        if(response.status == 200){
          Swal.fire('Éxito', response.message, 'success');
          if(this.obj.StatusContrato == 1){
            this.accionStatus1(false, 0, 0);
          }
          else if(this.obj.StatusContrato == 21){
            this.accionStatus2(false, 0, 0);
          }
          this.getRegistros();
        }else if (response.status == 500){      
          Swal.fire('Error', response.message, 'error');
        }else {
          Swal.fire("Atención",response.message,"info");
        }
      }).catch(error=>{ 
        this.loading.onDoneRequest();     
        Swal.fire('Error de conexión con el servidor', error.toString(), 'error');
      })
    }
  }

  public updateStatusEXT(){
    if(this.obj.StatusContrato == 1 && this.obj.UrlContrato == ""){
      Swal.fire('Atención', "Debe cargar un documento para poder continuar.", 'warning');
      return false;
    }else{
      this.loading.onLoadingRequest();
      this._service.updateStatusEXT(this.obj)
      .then(response=>{
        this.loading.onDoneRequest();
        if(response.status == 200){
          Swal.fire('Éxito', response.message, 'success');
          if(this.obj.StatusContrato == 1){
            this.accionStatus1Ext(false, 0);
          }
          else if(this.obj.StatusContrato == 21){
            this.accionStatus2(false, 0, 0);
          }
          this.getRegistros();
        }else if (response.status == 500){      
          Swal.fire('Error', response.message, 'error');
        }else {
          Swal.fire("Atención",response.message,"info");
        }
      }).catch(error=>{ 
        this.loading.onDoneRequest();     
        Swal.fire('Error de conexión con el servidor', error.toString(), 'error');
      })
    }
  }

  public autorizarDocumento(aut:boolean){
    if(!aut && (this.contr.Comentario == "" || !this.contr.Comentario)){
      Swal.fire('Atención', "Debes especificar el motivo de rechazo", 'error');
    }else{
      var tipo = aut ? "Autorizar" : "Rechazar";
      this.contr.FlagAut = aut;
      Swal.fire({
        title: 'Atención',
        text: "¿Estas seguro que deseas " + tipo + " el documento? Tus comentarios serán enviados.",
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.isConfirmed) {
          this.loading.onLoadingRequest();
          this._service.updateAutorizarDocumentoEXT(this.contr)
          .then(response=>{
            this.loading.onDoneRequest();
            if(response.status == 200){
              Swal.fire('Éxito', response.message, 'success');
              this.accionStatus2Ext(false, 0);
            
              this.getRegistros();
            }else{
              Swal.fire('Error', response.message, 'error');
            }
          }).catch(error=>{  
            this.loading.onDoneRequest();    
            Swal.fire('Error de conexión con el servidor', error.toString(), 'error');
          })
        }
      })
    }
  }

  public autorizarContratoExt(aut:boolean){
    if(!aut && (this.contr.Comentario == "" || !this.contr.Comentario)){
      Swal.fire('Atención', "Debes especificar el motivo de rechazo", 'error');
    }else{
      var tipo = aut ? "Autorizar" : "Rechazar";
      this.contr.FlagAut = aut;
      Swal.fire({
        title: 'Atención',
        text: "¿Estas seguro que deseas " + tipo + " el contrato? Tus comentarios serán enviados.",
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.isConfirmed) {
          this.loading.onLoadingRequest();
          this._service.updateAutorizarContratoEXT(this.contr)
          .then(response=>{
            this.loading.onDoneRequest();
            if(response.status == 200){
              Swal.fire('Éxito', response.message, 'success');
              this.accionStatus3(false, 0, 0);
            
              this.getRegistros();
            }else{
              Swal.fire('Error', response.message, 'error');
            }
          }).catch(error=>{  
            this.loading.onDoneRequest();    
            Swal.fire('Error de conexión con el servidor', error.toString(), 'error');
          })
        }
      })
    }
  }

  public updateStatus3(aut:boolean){
    if(!aut && (this.contr.Comentario == "" || !this.contr.Comentario)){
      Swal.fire('Atención', "Debes especificar el motivo de rechazo", 'error');
    }else{
      var tipo = aut ? "Autorizar" : "Rechazar";
      this.contr.FlagAut = aut;
      Swal.fire({
        title: 'Atención',
        text: "¿Estas seguro que deseas " + tipo + " el contrato? Tus comentarios serán enviados.",
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.isConfirmed) {
          this.loading.onLoadingRequest();
          this._service.updateAutorizarContrato(this.contr)
          .then(response=>{
            this.loading.onDoneRequest();
            if(response.status == 200){
              Swal.fire('Éxito', response.message, 'success');
              this.accionStatus3(false, 0, 0);
            
              this.getRegistros();
            }else{
              Swal.fire('Error', response.message, 'error');
            }
          }).catch(error=>{  
            this.loading.onDoneRequest();    
            Swal.fire('Error de conexión con el servidor', error.toString(), 'error');
          })
        }
      })
    }
  }

  public openVisitSeg(open:boolean, EmpresaID: number){
    if(open){
      this.loading.onLoadingRequest();
      this._service.getDatosVisita(EmpresaID)
      .then(response=>{
        this.loading.onDoneRequest();
        if(response.status == 200){
          if(response.content != null){
            this.visit = response.content;
            this.visit.EmpresaID = EmpresaID;
            var dir = JSON.parse(this.visit.DirOficina);
            this.visit.DirOficina = dir.Calle + " " + dir.Num + ", " + dir.Colonia + ", " + dir.Municipio + ", " + dir.Estado + " CP:" + dir.CP;
            var dir2 = JSON.parse(this.visit.DirPatio);
            this.visit.DirPatio = dir2.Calle + " " + dir2.Num + ", " + dir2.Colonia + ", " + dir2.Municipio + ", " + dir2.Estado + " CP:" + dir2.CP;
          }
        }else if(response.status == 500){
          Swal.fire("Error",response.message,"error");
        }
      })
      .catch(error=>{
        this.loading.onDoneRequest();
        console.log(error.toString());
      });
    }
    this.accionStatus4Modal = open;
  }

  public openNoAprobada(EmpresaID: number){
    Swal.fire({
      title: 'Solicitar Visita de Certificación',
      text: "¿Estas seguro que deseas solicitar nuevamente la VISITA DE CERTIFICACIÓN?",
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.obj.EmpresaID = EmpresaID;
        this.obj.StatusContrato = 222;
        this.updateStatus1();
      }
    })
  }

  public openDeclinada(EmpresaID: number){
    Swal.fire({
      title: 'Atención',
      text: "¿Estas seguro que deseas dar por finalizado el contrato?",
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.obj.EmpresaID = EmpresaID;
        this.obj.StatusContrato = 223;
        this.updateStatus1();
      }
    })
  }

  public openCerrarAlerta(EmpresaID: number){
    Swal.fire({
      title: 'Atención',
      text: "¿Estas seguro que deseas cerrar la alerta?",
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.obj.EmpresaID = EmpresaID;
        this.obj.StatusContrato = 221;
        this.updateStatus1();
      }
    })
  }

  public openFinalizarExt(EmpresaID: number){
    Swal.fire({
      title: 'Atención',
      text: "¿Estas seguro que deseas dar por finalizado el contrato?",
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.obj.EmpresaID = EmpresaID;
        this.obj.StatusContrato = 3;
        this.updateStatusEXT();
      }
    })
  }

  public openFinalizar(EmpresaID: number){
    Swal.fire({
      title: 'Atención',
      text: "¿Estas seguro que deseas dar por finalizado el contrato?",
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.obj.EmpresaID = EmpresaID;
        this.obj.StatusContrato = 3;
        this.updateStatus1();
      }
    })
  }

  readUrl(event:any) { 
    if (event.target.files && event.target.files[0]) { 
      this.loading.onLoadingRequest();
      this.uploadedFiles = event.target.files;
      let formdata = new FormData();
      for (let i = 0; i< this.uploadedFiles.length; i++){
        formdata.append("contrato", this.uploadedFiles[i], this.uploadedFiles[i].name);
      }
      this._service.uploadFile(formdata).then((res)=>{
        this.loading.onDoneRequest();
        this.obj.UrlContrato = res[0];
      });
    }
  }

  readUrlEXT(event:any) { 
    if (event.target.files && event.target.files[0]) { 
      this.loading.onLoadingRequest();
      this.uploadedFiles = event.target.files;
      let formdata = new FormData();
      for (let i = 0; i< this.uploadedFiles.length; i++){
        formdata.append("docExtranjera", this.uploadedFiles[i], this.uploadedFiles[i].name);
      }
      this._service.uploadFile(formdata).then((res)=>{
        this.loading.onDoneRequest();
        this.obj.UrlContrato = res[0];
      });
    }
  }

  public preview(ContratoSolicitudID:number){
    window.location.href = GLOBAL.urlIMG + '/General/DownloadPdfContrato?documentoId=' + ContratoSolicitudID;
  }

  public previewExt(ContratoExtranjeraID:number){
    window.location.href = GLOBAL.urlIMG + '/General/DownloadPdfExtranjera?documentoId=' + ContratoExtranjeraID;
  }
}
