<div id="site_wrapper">
    <div class="contenedor-principal">
        <div class="row mapa-sitio">
            <div class="col-12">
                <h2>Reporte Administrador</h2> 
            </div>
        </div>
        <div class="row principal">
            <div class="col-sm-7 col-md-8 col-lg-9">
                <input type="text" [(ngModel)]="searchText" class="buscador form-control"  placeholder="Buscador">
            </div>
        </div>
        <div class="row filtros">
            <div class="col-sm-12 col-md-6 col-lg-3">
                <label>Empresa</label>
                <ng-select
                    [searchable]="true"
                    [(ngModel)]="filter.empresaID"
                    (change)="getEmpresaAlerta()">
                    <ng-option [value]="0">Seleccionar...</ng-option>
                    <ng-option [value]="group.EmpresaID" *ngFor="let group of catalog.empresas;">
                        {{group.Nombre}}
                    </ng-option>
                </ng-select>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3">
                <label>Tipo de Mandatorio</label>
                <select [(ngModel)]="filter.tm" class="form-control">
                    <option value="Todos">Todos</option>
                    <option value="Para Firma de Contrato">Para Firma de Contrato</option>
                    <option value="Para Visita de Certificación">Para Visita de Certificación</option>
                    <option value="Para Iniciar Operaciones">Para Iniciar Operaciones</option>
                    <option value="Deseable">No Mandatorio</option>
                </select>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3">
                <label>Status</label>
                <select [(ngModel)]="filter.status" class="form-control">
                    <option value="Todos">Todos</option>
                    <option value="Cargado">Cargado</option>
                    <option value="No cargado">No cargado</option>
                </select>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3">
                <label>Item</label>
                <select class="form-control" [(ngModel)]="filter.itemID">
                    <option value="0">Seleccionar...</option>
                    <option value="{{group.ItemID}}" *ngFor="let group of catalog.items;">{{group.Nombre}}</option>
                </select>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3">
                <button class='btn btn-danger btn-fluid' type='button' style='margin-top: 31px;' (click)="getRegistros()">
                    FILTRAR
                </button>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3">
                <button class='btn btn-warning btn-fluid' type='button' style='margin-top: 31px;'  (click)="exportexcel()">
                    EXCEL
                </button>
            </div>
        </div>
        <div class="row alertas mt-3">
            <div class="col-12">
                <h4>Correo Alerta</h4>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3">
                <label>Frecuencia</label>
                <input type="number" [(ngModel)]="alerta.frecuencia" class="form-control"  placeholder="Frecuencia">
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3">
                <label>Status</label>
                <select [(ngModel)]="alerta.status" class="form-control">
                    <option value="Todos">Todos</option>
                    <option value="Cargado">Cargado</option>
                    <option value="No cargado">No cargado</option>
                </select>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3">
                <label>Item</label>
                <ng-select                      
                    multiple="true" [clearable]="false"
                    [searchable]="true"
                    [(ngModel)]="alerta.itemID">
                    <ng-option [value]="0">Seleccionar...</ng-option>
                    <ng-option [value]="group.ItemID" *ngFor="let group of catalog.items;">
                        {{group.Nombre}}
                    </ng-option>
                </ng-select>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3">
                <button class='btn btn-danger btn-fluid' type='button' style='margin-top: 31px;' (click)="updateEmpresaAlerta()">
                    GUARDAR
                </button>
            </div>
        </div>
        <div class="row tabla-catalogo">  
            <div class="col-12">
                <table class="table table-responsive" id="tableResumen"> 
                    <thead>
                        <tr>
                            <th width="25%">EMPRESA</th>
                            <th width="25%">ITEM</th>
                            <th width="40%">REACTIVO</th>
                            <th width="20%">TIPO MANDATORIO</th>
                            <th width="10%">STATUS</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let group of datos | reporteAdmin: searchText; ">
                            <td>{{group.Empresa}}</td>
                            <td>{{group.Item}}</td>
                            <td>{{group.Reactivo}}</td>
                            <td>{{group.Mandatorio}}</td>
                            <td>{{group.Status}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>             
        </div>
    </div>
</div>

<app-loading-efect></app-loading-efect>