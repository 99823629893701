<div id="site_wrapper">
    <div class="contenedor-principal">
        <div class="row">
            <div class="col-sm-12 btn-regresar-2">
              <a (click)="regresar()" style="cursor: pointer;" class="float-left link-regresar"><img src="../../../assets/IMG/arrow_back.png" /></a>
            </div>
        </div>
        <div class="row mapa-sitio">
            <div class="col-12">
                <h2>Status Contrato {{nombre}}</h2> 
            </div>
        </div>
        <div class="row tabla-catalogo">  
            <div class="col-12">
                <table class="table table-responsive"> 
                    <thead>
                        <tr>
                            <th width="20%">EMPRESA</th>
                            <th width="20%">STATUS</th>
                            <th width="50%">DESCRIPCION</th>
                            <th width="10%">FECHA DE CREACIÓN</th>
                            <th width="5%">CONTRATO</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let group of catalog;">
                            <td>{{group.Nombre}}</td>
                            <td>{{group.StatusNombre}}</td>
                            <td>{{group.Descripcion}}</td>
                            <td>{{group.INSERT_DATE | date:'dd/MM/yyyy'}}</td>
                            <td>
                                <div *ngIf="group.ContratoSolicitudID != null">
                                    <img class="btn-accion" src="../../../assets/IMG/preview-background-gray.png" (click)="preview(group.ContratoSolicitudID)" width="40" height="30" title="Ver contrato">
                                </div>
                            </td>
                            <td>
                                <div *ngIf="group.StatusID == 2111">
                                    <img class="btn-accion" src="../../../assets/IMG/circulo_rojo.png" width="20" height="20">
                                </div>
                                <div *ngIf="group.FlagActivo && group.StatusID != 2111">
                                    <img class="btn-accion" src="../../../assets/IMG/circulo_verde.png" width="20" height="20">
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>        
            <div class="col-12" style="margin-top: 10px;">
                <button *ngIf="validateClaim('Contrato Reiniciar Proceso')" class="btn btn-danger mr-3" (click)="resetContract()">Reiniciar Proceso Contrato</button>
                <button *ngIf="flagCertificacion && validateClaim('Contrato Nueva Certificacion')" class="btn btn-danger" (click)="accionStatus1(true)">Nueva Certificación</button>
            </div>     
        </div>
        
        <div class="row pt-3" *ngIf="certificaciones && certificaciones.length > 0">
            <div class="col-12">
                <h2>Certificaciones</h2> 
            </div>
        </div>
        <div class="row tabla-catalogo" *ngIf="certificaciones && certificaciones.length > 0">  
            <div class="col-12">
                <table class="table table-responsive"> 
                    <thead>
                        <tr>
                            <th width="10%">NO CERTIFICACION</th>
                            <th width="10%">NIVEL DE SEGURIDAD</th>
                            <th width="10%">NO VUELTA</th>
                            <th width="20%">STATUS</th>
                            <th width="50%">DESCRIPCION</th>
                            <th width="10%">FECHA DE CREACIÓN</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let group of certificaciones;">
                            <td>{{group.NoCertificacion}}</td>
                            <td>{{group.NivelSeguridad}}</td>
                            <td>{{group.NoVuelta}}</td>
                            <td>{{group.StatusNombre}}</td>
                            <td>{{group.Descripcion}}</td>
                            <td>{{group.INSERT_DATE | date:'dd/MM/yyyy'}}</td>
                            <td>
                                <div *ngIf="group.FlagActivo">
                                    <img class="btn-accion" src="../../../assets/IMG/circulo_verde.png" width="20" height="20">
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

    </div>
</div>

<div class="modal fade" id="newCertificacionModal" tabindex="-1" role="dialog" aria-hidden="true" [ngStyle]="{'display': newCertificacionModal ? 'block' : 'none', 'opacity': 1}"> 
    <div class="modal-dialog modal-md modal-dialog-centered" role="dialog" >
      <div class="modal-content">
        <div class="modal-header mdl-hdr text-center">
          <h5 class="modal-title w-100"></h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="accionStatus1(false)">
            <span aria-hidden="true" class="mdl-close" >&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <form #createForm1="ngForm" (ngSubmit)="newCertificacion()" autocomplete="off" appIdentityRevealed>
                <div class="form-group">
                    <label for="NivelSeguridad">NIVEL DE SEGURIDAD</label>
                    <select class="form-control" #NivelSeguridad="ngModel" name="NivelSeguridad" [(ngModel)]="nivelSeguridad">
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                    </select>
                </div>
                <div class="div-btns row pt-3">   
                    <div class="col-6">
                      <button class="btn btn-fluid btn-Eliminar btnTableUp" (click)="accionStatus1(false)" data-dismiss="modal" type="button">CANCELAR</button>            
                    </div>
                    <div class="col-6">     
                      <button class="btn btn-fluid btn-layout btnTableUp" [disabled]="createForm1.invalid">CONTINUAR</button>    
                    </div>
                </div>
            </form>            
        </div>       
      </div>
    </div>
</div>

<app-loading-efect></app-loading-efect>