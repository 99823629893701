import { Component, OnInit } from '@angular/core';
import { NoticiaService } from 'src/app/services/noticia/noticia.service';
import { UsuariosService } from 'src/app/services/usuarios/usuarios.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { LoadingEfectComponent } from 'src/app/Layout/loading-efect/loading-efect.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-config-noticias',
  templateUrl: './config-noticias.component.html',
  styleUrls: ['./config-noticias.component.css']
})
export class ConfigNoticiasComponent implements OnInit {
  loading = new LoadingEfectComponent();
  searchText;
  public catalog:any[];
  public texto:string = "";

  identity = JSON.parse(localStorage.getItem('identity_user'));
  claimEdit = false;

  constructor(
    private _service:NoticiaService,
    private _serviceUsuarios:UsuariosService,
    public router: Router
  ) { }

  ngOnInit(): void {
    this.getRegistros();
    this.registrarLog();
  }

  public registrarLog(){
    let clase = 'Administración de Noticias';
    let obj = {
      //Controller: obj.Controller,
      Class: clase,
      //Table: obj.Table,
      //ID: obj.ID,
      Message: 'ACCESO'
    }
    this._serviceUsuarios.registrarLog(obj);
  }

  public getRegistros(){
    this.loading.onLoadingRequest();
    this._service.getNoticiasByUser(0)
    .then(response=>{
      this.loading.onDoneRequest();
      if(response.status == 200){
        if(response.content != null && response.content.length > 0){
          this.catalog = response.content;
        }else{
          this.catalog = [];
          Swal.fire("Sin registros","Aún no se han registrado noticias","info");      
        }
      }else if(response.status == 500){
        Swal.fire("Error",response.message,"error");
      }
    })
    .catch(error=>{
      this.loading.onDoneRequest();
      console.log(error.toString());
    });
  }

  public update(id:number){
    this.router.navigate(['/sa-rm/admin/update-noticias/' + id]);
  }

  public delete(id:number){
    Swal.fire({
      title: 'Atención',
      text: "¿Estas seguro que deseas eliminar la noticia?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.loading.onLoadingRequest();
        this._service.deleteNoticia(id)
        .then(response=>{
          this.loading.onDoneRequest();
          if(response.status == 200){
            Swal.fire('Éxito', response.message, 'success');
            let obj = {
              //Controller: obj.Controller,
              //Class: 'Noticia',
              Table: 'Noticia',
              ID: id,
              Message: 'ELIMINAR'
            }
            this._serviceUsuarios.registrarLog(obj);
          
            this.getRegistros();
          }else{
            Swal.fire('Error', response.message, 'error');
          }
        }).catch(error=>{  
          this.loading.onDoneRequest();    
          Swal.fire('Error de conexión con el servidor', error.toString(), 'error');
        })
      }
    })
  }
}
