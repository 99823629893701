<div id="site_wrapper">
    <div class="contenedor-principal">
        <div class="row mapa-sitio">
            <div class="col-12">
                <h2>Opinión de Cumplimiento</h2> 
            </div>
        </div>
        <div class="row filtros">
            <div class="col-sm-12 col-md-6 col-lg-3">
                <label>AÑO</label>
                <select [(ngModel)]="filter.anio" class="form-control">
                    <option *ngFor="let ani of anio;" (value)="ani">{{ani}}</option>
                </select>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3">
                <button class='btn btn-danger btn-fluid' type='button' style='margin-top: 31px;' (click)="getRegistros()">
                    Filtrar
                </button>
            </div>
        </div>
        
        <div class="row tabla-catalogo">  
            <div class="col-12">
                <table class="table table-responsive table-sm table-hover" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"> 
                    <thead>
                        <tr>
                            <th width="10%">Línea Transportista</th>
                            <th width="30%">SPN</th>
                            <th width="30%">Enero</th>
                            <th width="30%">Febrero</th>
                            <th width="30%">Marzo</th>
                            <th width="30%">Abril</th>
                            <th width="30%">Mayo</th>
                            <th width="30%">Junio</th>
                            <th width="30%">Julio</th>
                            <th width="30%">Agosto</th>
                            <th width="30%">Septiembre</th>
                            <th width="30%">Octubre</th>
                            <th width="30%">Noviembre</th>
                            <th width="30%">Diciembre</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let group of datos;">
                            <td>{{group.NombreEmpresa}}</td>
                            <td>{{group.spn}}</td>
                            <td>{{group.Enero}}</td>
                            <td>{{group.Febrero}}</td>
                            <td>{{group.Marzo}}</td>
                            <td>{{group.Abril}}</td>
                            <td>{{group.Mayo}}</td>
                            <td>{{group.Junio}}</td>
                            <td>{{group.Julio}}</td>
                            <td>{{group.Agosto}}</td>
                            <td>{{group.Septiembre}}</td>
                            <td>{{group.Octubre}}</td>
                            <td>{{group.Noviembre}}</td>
                            <td>{{group.Diciembre}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>             
        </div>
    </div>
</div>

<app-loading-efect></app-loading-efect>