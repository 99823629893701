<div id="site_wrapper">
    <div class="contenedor-principal">
      <div class="row mapa-sitio">
          <div class="col-sm-12 col-md-9">
              <div>
                  <a class="lbl-noActive" href="/#/sa-rm/admin/config">Configuraciones > </a><a href="/#/sa-rm/admin/config" class="lbl-noActive">&nbsp;Lista > </a><label class="lbl-active">&nbsp;Roles y Permisos</label>
              </div>
              <h2>Roles y Permisos</h2> 
          </div>
          <div class="col-sm-12">
              <a href="/#/sa-rm/admin/config" class="float-left link-regresar" ><img src="../../../assets/IMG/arrow_back.png" /></a>
          </div>
      </div>
      <div class="row principal">
          <div class="col-sm-7 col-md-8 col-lg-9 col-xl-10">
              <input type="text" [(ngModel)]="searchRole" class="buscador form-control" placeholder="Buscador">
          </div>
          <div class="col-sm-5 col-md-4 col-lg-3 col-xl-2">
              <button class='btn-fluid float-right btnTableUp' (click)='open(true, 0)' type='button'>
                   NUEVO ROL
              </button>
          </div>
      </div>
      <div class="row tabla-catalogo">  
          <div class="col-12">
              <table class="table table-responsive"> 
                  <thead>
                  <tr>
                      <th width="25%">ROL</th>
                      <th width="50%">DESCRIPCIÓN</th>
                      <th width="15%">FECHA DE CREACIÓN</th>
                      <th width="10%" class="no-sort"></th>
                      <th class="no-sort"></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let group of roles | role: searchRole; ">
                      <td>{{group.Nombre}}</td>
                      <td>{{group.Descripcion}}</td>
                      <td>{{group.FechaCreacion | date:'dd/MM/yyyy'}}</td>
                      <td>
                          <button class="btn btn-ch float-right btnTableUp" (click)='open(true, group.ID)'>Editar</button>
                      </td>
                      <td>
                          <button class="btn btn-Eliminar btn-ch float-right btnTableUp" (click)="delete(group.ID)">Eliminar</button>
                      </td>
                  </tr>
                  </tbody>
              </table>
          </div>             
      </div>
    </div>
  </div>
  
<div class="modal fade" id="rolModal" tabindex="-1" role="dialog" aria-hidden="true" [ngStyle]="{'display': mdlRol ? 'block' : 'none', 'opacity': 1}" style="overflow: auto;"> 
    <div class="modal-dialog modal-lg modal-dialog-centered" role="dialog" >
      <div class="modal-content">
        <div class="modal-header mdl-hdr text-center">
          <h5 class="modal-title w-100"></h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="open(false, 0)">
            <span aria-hidden="true" class="mdl-close" >&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <form #createForm="ngForm" (ngSubmit)="update()" autocomplete="off" appIdentityRevealed method="POST" enctype="multipart/form-data">
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label for="Nombre">ROL</label>
                    <input type="text" class="form-control" #Nombre="ngModel" name="Nombre" [(ngModel)]="obj.Nombre" placeholder="Ingresa texto"  required />
                    <div *ngIf="Nombre.invalid"
                        class="lbl-error">
                        <span *ngIf="Nombre.errors.required">
                            El campo es requerido
                        </span>            
                    </div>
                  </div>
                </div>
                <div class="col-12">
                    <div class="form-group">
                      <label for="Descripcion">DESCRIPCIÓN</label>
                      <input type="text" class="form-control" #Descripcion="ngModel" name="Descripcion" [(ngModel)]="obj.Descripcion" placeholder="Ingresa texto"  required />
                      <div *ngIf="Descripcion.invalid"
                          class="lbl-error">
                          <span *ngIf="Descripcion.errors.required">
                              El campo es requerido
                          </span>            
                      </div>
                    </div>
                </div>
                <div class="col-12">
                  <h5>PERMISOS</h5>
                </div>
                <div *ngFor="let permiso of catalog.permisoLists" class="col-12">
                 <hr>
                 <label style="font-weight: bold;">{{permiso[0].moduloPermiso}}</label>
                 <div *ngFor="let permisos of permiso">
                   <label class="switch">
                       <input type="checkbox" [checked]="validateClaim(permisos.PermisoID)" name="{{permisos.nombrePermiso}}" (change)="updateModuleClaim(permisos.PermisoID)">
                       <span class="slider round"></span>
                   </label>
                   &nbsp;
                   <label>{{permisos.descripcionPermiso}}</label>
                  </div>
                 </div>
              </div>
                <div class="div-btns row pt-3">   
                  <div class="col-6">
                    <button class="btn btn-fluid btn-Eliminar btnTableUp" (click)="open(false, 0)" data-dismiss="modal" type="button">CANCELAR</button>            
                  </div>
                  <div class="col-6">     
                    <button class="btn btn-fluid btnTableUp" [disabled]="createForm.invalid">GUARDAR</button>    
                  </div>
                </div>
            </form>            
        </div>       
      </div>
    </div>
  </div>

  <app-loading-efect></app-loading-efect>