<div id="site_wrapper">
    <div class="contenedor-principal">
        <div class="row mapa-sitio">
            <div class="col-12">
                <h2>Centro de Ayuda</h2> 
            </div>
        </div>
        <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item">
              <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" (click)="getRegistros(1)" role="tab" aria-controls="home" aria-selected="true">Nuevos</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" (click)="getRegistros(2)" role="tab" aria-controls="profile" aria-selected="false">En curso</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" id="review-tab" data-toggle="tab" href="#review" (click)="getRegistros(3)" role="tab" aria-controls="review" aria-selected="false">Resueltos</a>
            </li>
        </ul>
        <div class="row tabla-catalogo">  
            <div class="col-12">
                <table class="table table-responsive table-sm table-hover" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"> 
                    <thead>
                        <tr>
                            <th width="5%">ID</th>
                            <th width="20%">ITEM</th>
                            <th width="40%">REACTIVO</th>
                            <th width="5%">NUM PROVEEDOR</th>
                            <th width="10%">NOMBRE EMPRESA</th>
                            <th width="5%">TIPO PERSONA</th>
                            <th width="5%">STATUS</th>
                            <th width="10%">FECHA DE CREACIÓN</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let group of catalog;" (click)="modalTicket(true, group.AsistenciaID)" style="cursor: pointer;">
                            <td>{{group.AsistenciaID}}</td>
                            <td>{{group.Item}}</td>
                            <td>{{group.Reactivo}}</td>
                            <td>{{group.NumProveedor}}</td>
                            <td>{{group.NombreEmpresa}}</td>
                            <td>{{group.NombreTipoPersona}}</td>
                            <td>{{group.NombreStatus}}</td>
                            <td>{{group.FechaCreacion | date:'yyyy/MM/dd'}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>             
        </div>
    </div>
</div>

<div class="modal fade" id="mdlTicket" tabindex="-1" role="dialog" aria-hidden="true" [ngStyle]="{'display': mdlTicket ? 'block' : 'none', 'opacity': 1}"> 
    <div class="modal-dialog modal-xl modal-dialog-centered" role="dialog" >
      <div class="modal-content">
        <div class="modal-header mdl-hdr text-center">
          <h5 class="modal-title w-100">TICKET</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Minimizar" (click)="minTicket(true)" style="line-height: .5;">
            <span aria-hidden="true" class="mdl-close" >-</span>
          </button>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modalTicket(false,0)" style="line-height: .5;">
            <span aria-hidden="true" class="mdl-close" >&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <div class="row principal">
                <div class="col-12">
                    <label style="font-weight: bold; color: #ffcc01;">ITEM:</label><span>   {{asistencia.Item}}</span>
                </div>
                <div class="col-12">
                    <label style="font-weight: bold; color: #ffcc01;">REACTIVO:</label><span>   {{asistencia.Reactivo}}</span>
                </div>
                <div class="col-12">
                    <label style="font-weight: bold; color: #ffcc01;">Nombre Empresa:</label><span>   {{asistencia.NombreEmpresa}}</span>
                </div>
                <div class="col-12" *ngFor="let group of asistencia.Comentarios" [ngStyle]="{'background-color': group.Color}">
                    <label style="font-weight: bold;">{{group.NombreEmisor}}</label><br>
                    <label>{{group.Comentario}}</label>
                </div>
                <div class="col-12 pt-3">
                    <label>Mensaje</label>
                    <textarea [(ngModel)]="asistenciaREQ.Comentario" class="form-control"></textarea>
                </div>
            </div>
            <div class="div-btns">
                <button class="btn-layout btn-med btnTableUp mt-4 float-right ml-5" (click)="crearTicket(3)" [hidden]="asistencia.Status == 3 || identity.empresa">CERRAR TICKET</button>  
                <button class="btn-layout btn-med btnTableUp mt-4 float-right ml-5" (click)="crearTicket(2)" [hidden]="asistencia.Status == 3">ENVIAR</button>  
                <button class="btn-layout btn-med btnTableUp mt-4 float-right ml-5" (click)="openPreviewItem(asistencia.ItemEmpresaID)" [hidden]="asistencia.ItemEmpresaID == 0">REVISAR REACTIVO</button>  
            </div>    
        </div>       
      </div>
    </div>
</div>

<app-loading-efect></app-loading-efect>