<div id="site_wrapper">
    <div class="contenedor contenedor-login">
        <div class="card-login">
          <form #loginForm="ngForm" autocomplete="off" appIdentityRevealed>
             <img src="https://firebasestorage.googleapis.com/v0/b/glide-prod.appspot.com/o/icon-images%2Fanonymous-ca30ff47-9d5f-40ef-b7cd-85de1b2bde95.jpg?alt=media&amp;token=5f82f486-b3d8-483d-8e08-635fd3f943a3" alt="" class="img-logo">
             <div class="login-contenido">
                <label for="" class="lbl-bienvenido pb-3">BIENVENIDO</label>
                <label for="" class="lbl-txt mb-0">Expediente Universal del Transportista</label>
                <label for="" class="lbl-txt pb-3" style="font-size: 14px;">(Powered by SA-RM)</label>
                <div class="form-group" [hidden]="btnContinuar">  
                    <input autocomplete="off"  type="email" class="form-control" #User="ngModel" name="User" [(ngModel)]="obj.usuario" placeholder="Correo electrónico"  required/>
                </div>
                <div class="form-group" [hidden]="btnContinuar">  
                    <input autocomplete="new-password"  type="password" class="form-control" #Password="ngModel" name="Password" [(ngModel)]="obj.password" placeholder="Contraseña"  required/>
                </div>
                <div class="form-group" [hidden]="btnLogin">  
                    <input autocomplete="new-password"  type="password" class="form-control" #Pass="ngModel" name="Pass" [(ngModel)]="obj.token" placeholder="Token de acceso"  required/>
                </div>
                <div class="row">
                    <div class="col-12" [hidden]="btnContinuar">
                        <a target="_blank" href="{{GLOBALurl}}/WebAccount/ForgotPassword" class="lbl-txt" style="float: left;">Olvidé mi contraseña</a>
                    </div>
                    <div class="col-12">
                        <a target="_blank" href="https://drive.google.com/file/d/1j5TUhoFoUuQfQ8Uw7O76NLE80trROEdD/view?usp=sharing" class="lbl-txt pb-3" style="float: left;">Aviso de privacidad</a>
                    </div>
                </div>
                <div class="custom-control custom-checkbox pb-3" style="float: left;" [hidden]="btnContinuar">
                    <input type="checkbox" id="Terms" name="Terms" #Terms="ngModel" [(ngModel)]="obj.terminos" class="custom-control-input" required>
                    <label for="Terms" class="custom-control-label lbl-txt">Acepto los términos y condiciones.</label>
                </div>
                <div class="div-btns">
                    <button class="btn btn-md btn-login form-control" [hidden]="btnContinuar" (click)="continuar()">Continuar</button>
                    <button class="btn btn-md btn-login form-control" [hidden]="btnLogin" (click)="login()">Log In</button>
                    <a class="lbl-txt pb-3" [hidden]="btnLogin" style="cursor: pointer;" (click)="regresar()">Regresar</a>
                </div>
             </div>
          </form>
       </div>
    </div>
 </div>