import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'item'
})
export class ItemPipe implements PipeTransform {

  transform(items: any[], searchText: string): unknown {
    if (!items) return [];
    if (!searchText) return items;
  
    searchText = searchText.toLowerCase();
  
    return items.filter( it => {
      return it.Nombre.toLowerCase().includes(searchText)
      || it.NombreGrupo.toLowerCase().includes(searchText)
      || it.NacionalExtranjera.toLowerCase().includes(searchText)
      || it.Default.toLowerCase().includes(searchText)
      || it.ItemID == searchText
      || it.totalPreguntas == searchText
      || it.FechaCreacion.toLowerCase().includes(searchText);
    });
  }

}
