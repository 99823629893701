import { Component, OnInit } from '@angular/core';
import { ClientesService } from 'src/app/services/clientes/clientes.service';
import { ReclamoService } from 'src/app/services/reclamo/reclamo.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { LoadingEfectComponent } from 'src/app/Layout/loading-efect/loading-efect.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-nuevo-reclamo',
  templateUrl: './nuevo-reclamo.component.html',
  styleUrls: ['./nuevo-reclamo.component.css']
})
export class NuevoReclamoComponent implements OnInit {
  public fechaHoy: Date = new Date();
  loading = new LoadingEfectComponent();
  identity = JSON.parse(localStorage.getItem('identity_user'));

  public catalog:any[];
  public Obj:any = {};

  constructor(
    private _service:ClientesService,
    private _serviceReclamo:ReclamoService,
    public router: Router
  ) { }

  ngOnInit(): void {
    this.verifyClaims();
    this.getRegistros();
  }

  verifyClaims(){
    var claim = this.identity.permisoLists.find(x => x.nombrePermiso === 'Alta Reclamos');
    if(claim == undefined && claim == null){
      this.router.navigate(['/sa-rm/admin']);
    }
  }

  public getRegistros(){
    this.loading.onLoadingRequest();
    this._service.getCliente(0)
    .then(response=>{
      if(response.status == 200){
        if(response.content != null && response.content.length > 0){
          this.loading.onDoneRequest();
          this.catalog = response.content;
        }
      }else if(response.status == 500){
        this.loading.onDoneRequest();
        Swal.fire("Error",response.message,"error");
      }
    })
    .catch(error=>{
      this.loading.onDoneRequest();
      console.log(error.toString());
    });
  }

  public update(){
    this.loading.onLoadingRequest();
    this._serviceReclamo.createReclamo(this.Obj)
        .then(response=>{
          this.loading.onDoneRequest();   
          if(response.status == 200){
            Swal.fire("Registro exitoso","Se ha dado de alta el reclamo de manera correcta.","success")
            .then(rs=>{
              this.router.navigate(['/sa-rm/admin/reclamo-ft/' + response.EmpresaID]);
            });
          }else{
            Swal.fire('Error', response.message, 'error');
          }        
        }).catch(error=>{   
          this.loading.onDoneRequest();   
          Swal.fire('Error de conexión con el servidor', error.toString(), 'error');
        })
  }
}
