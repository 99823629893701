import { Component, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { LoadingEfectComponent } from 'src/app/Layout/loading-efect/loading-efect.component';
import { Router } from '@angular/router';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';

@Component({
  selector: 'app-opinion-cumplimiento',
  templateUrl: './opinion-cumplimiento.component.html',
  styleUrls: ['./opinion-cumplimiento.component.css']
})
export class OpinionCumplimientoComponent implements OnInit {
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  dtOptions:any = {
    dom: 'Bflrtip',
    buttons: [
        'excel'
    ],
    language: {
        "decimal": "",
        "emptyTable": "No hay información",
        "info": "Mostrando _START_ a _END_ de _TOTAL_ Resultados",
        "infoEmpty": "Mostrando 0 to 0 of 0 Resultados",
        "infoFiltered": "(Filtrado de _MAX_ total resultados)",
        "infoPostFix": "",
        "thousands": ",",
        "lengthMenu": "Mostrar _MENU_ Resultados",
        "loadingRecords": "Cargando...",
        "processing": "Procesando...",
        "search": "Buscador:",
        "zeroRecords": "Sin resultados encontrados",
        "paginate": {
            "first": "Primero",
            "last": "Ultimo",
            "next": "Siguiente",
            "previous": "Anterior"
        }
    }
  };
  dtTrigger = new Subject();

  identity = JSON.parse(localStorage.getItem('identity_user'));
  loading = new LoadingEfectComponent();
  searchText;

  anioAct: number = new Date().getFullYear();
  range = (start, end) => Array.from(Array(end - start + 1).keys()).map(x => x + start);
  anio = []

  public filter:any = {
    anio: this.anioAct
  };
  public datos:any[];

  constructor(
    private _service:DashboardService,
    private _router: Router
  ) { }

  ngOnInit(): void {
    this.verifyClaims();
    this.anio = this.range(2021,this.anioAct);
  }

  verifyClaims(){
    var claim = this.identity.permisoLists.find(x => x.nombrePermiso === 'Reporte Opinion de Cumplimiento');
    if(claim == undefined && claim == null){
      this._router.navigate(['/sa-rm/admin']);
    }
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  getRegistros(){
    this.dtOptions.destroy;
    this.loading.onLoadingRequest();
    this._service.getReporte_OpinionCumplimiento(this.filter.anio)
    .then(response=>{
      this.loading.onDoneRequest();
      if(response.status == 200){
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          this.datos = response.DR;
          // Destroy the table first
          dtInstance.destroy();
          this.dtTrigger.next();
          this.loading.onDoneRequest();
        });
      }else if(response.status == 500){
        Swal.fire("Error",response.message,"error");
      }
    })
    .catch(error=>{
      this.loading.onDoneRequest();
      console.log(error.toString());
    });
  }
}
