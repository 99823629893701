import { Component, OnInit } from '@angular/core';
import { GLOBAL } from './../../../../services/global';
import { ItemService } from 'src/app/services/items/item.service';
import { AsistenciaService } from 'src/app/services/asistencia/asistencia.service';
import { ClientesService } from 'src/app/services/clientes/clientes.service';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { LoadingEfectComponent } from 'src/app/Layout/loading-efect/loading-efect.component';

@Component({
  selector: 'app-reactivo-cl',
  templateUrl: './reactivo-cl.component.html',
  styleUrls: ['./reactivo-cl.component.css']
})
export class ReactivoClComponent implements OnInit {
  loading = new LoadingEfectComponent();
  public id = +this._route.snapshot.paramMap.get('id').split(",")[0];
  public ItemId = +this._route.snapshot.paramMap.get('id').split(",")[1];
  public objPreview: any = {};
  public preguntas:any = [];
  public idNewReact: number = 0;
  //Variables para Flujo Tickets
  public mdlTicket: boolean = false;
  public asistenciaREQ: any = {};

  //Variable Lista de botones de certificacion de reactivos a esconder
  public hiddenCert:number[] = [];

  uploadedFiles: Array<File>;
  identity = JSON.parse(localStorage.getItem('identity_user'));

  constructor(
    private _service:ItemService,
    private _clienteService:ClientesService,
    private _asistService:AsistenciaService,
    private router:Router,
    private _route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.getItem();
  }
  
  regresar(){
    this.router.navigate(["/sa-rm/client/alertas/" + this.ItemId]);
  }

  public getItem(){    
    this.loading.onLoadingRequest();
    this._clienteService.getItemByEmpresa(this.id)
    .then(response=>{
      this.loading.onDoneRequest();
      if(response.status == 200){
        if(response.content != null && response.content.length > 0){
          console.log(response.content[0])
          this.objPreview = response.content[0];
          this.getReactivosbyItem(response.content[0].ItemEmpresaID);
        }
        else{
          Swal.fire("Atención","No hay items que mostrar.","info");
        }
      }else if(response.status == 500){
        Swal.fire("Error",response.message,"error");
      }
    })
    .catch(error=>{
      this.loading.onDoneRequest();
      console.log(error.toString());
    });
  }

  public getReactivosbyItem(id:number){    
    this._clienteService.getReactivosbyItemAlerta(id, this.ItemId)
    .then(response=>{
      if(response.status == 200){
        if(response.content != null && response.content.length > 0){
          this.preguntas = response.content.map((x) =>
          {
            //Reactivos agrupados
            x.Group = !x.agrupados || x.agrupados == '' ? [] : x.agrupados.split(",").map( Number );
            if(x.Group.length > 0){
              this.hiddenCert.push.apply(this.hiddenCert,x.Group);
              console.log(this.hiddenCert);
            }
            //-------------------------------------------------------------------------------------------------------
            x.Aplica = String(x.NoAplica);
            x.Disponibilidad = x.Disponibilidad.slice(0, -9);
            if(x.VigenciaFIN){
              x.VigenciaFIN = x.VigenciaFIN.slice(0, -9);
            }
            if(x.NombreTipo == "Direccion" && x.Respuesta){
              x.Direccion = JSON.parse(x.Respuesta);
            }else{
              let Res = '{"Estado": "", "Municipio": "", "CP": 0, "Colonia": "", "Calle": "", "Num": ""}';
              x.Direccion = JSON.parse(Res);
            }
            return x
          });
          console.log(this.preguntas);
        }
        else{
          Swal.fire("Atención","No hay items que mostrar.","info");
        }
      }else if(response.status == 500){
        Swal.fire("Error",response.message,"error");
      }
    })
    .catch(error=>{
      console.log(error.toString());
    });
  }

  public anadirReact(id:number){
    let selectedReact = this.preguntas.find(x=>x.ReactivoItemEmpresaID == id);
    let newReact:any = JSON.parse(JSON.stringify(selectedReact));
    newReact.ReactivoItemEmpresaID = this.idNewReact - 1;
    newReact.Respuesta = null;
    newReact.VigenciaFIN = null;
    this.preguntas.push(newReact)
  }

  public certificar(id:number, status:number){
    let selectedReact = this.preguntas.find(x=>x.ReactivoItemEmpresaID == id);
    selectedReact.Status = status;
  }

  public guardar(status:number){
    this.preguntas.map((x) =>
    {
      if(x.NombreTipo == "Direccion"){
        x.Respuesta = JSON.stringify(x.Direccion);
      }
      if(status == 2){
        x.Status = 1;
      }
      return x
    });
    this.loading.onLoadingRequest();
    this.objPreview.Status = status;
    //-----Revisar preguntas agrupadas-----------
    let selectedReact = this.preguntas.filter(x=> x.agrupados && x.agrupados != '');
    if(selectedReact.length > 0){
      var ultimoReact = 0;
      var validReact = true;
      selectedReact.forEach((element) => {
        ultimoReact++;
        let reactsGV = this.preguntas.filter(x=> (element.Group.includes(x.ReactivoID) || element.ReactivoID == x.ReactivoID) && ((x.Opcional && !x.NoAplica) || !x.Opcional) && (!x.Respuesta || x.Respuesta == ''));
        if((element.Group.length + 1) != reactsGV.length && reactsGV.length > 0){
          validReact = false;
        }
        if(ultimoReact == selectedReact.length){
          if(validReact){
            this.save(status);
          }else{
            Swal.fire('Atención', 'Debes llenar todos los reactivos relacionados para poder continuar.', 'warning');
            this.loading.onDoneRequest();
            return false
          }
        }
      });
    }else{
      this.save(status);
    }
    //---------------------------------------
  }

  public save(status:number){
    this.objPreview.preguntas = this.preguntas;
    this._clienteService.updateReactivosEmpresa(this.objPreview)
    .then(response=>{
      this.loading.onDoneRequest();
      if(response.status == 300){
        Swal.fire('Atención', response.message, 'warning')
            .then(rs=>{
              this.regresar();
            });
      }
      else if(response.status != 200){
        Swal.fire('Error', response.message, 'error');
      }else{
        Swal.fire('Éxito', response.message, 'success')
            .then(rs=>{
              this.regresar();
            });
      }    
    }).catch(error=>{   
      this.loading.onDoneRequest();   
      Swal.fire('Error de conexión con el servidor', error.toString(), 'error');
    });
  }

  readUrl(event:any, id:number) { 
    if (event.target.files && event.target.files[0]) { 
      this.loading.onLoadingRequest();
      this.uploadedFiles = event.target.files;
      let formdata = new FormData();
      for (let i = 0; i< this.uploadedFiles.length; i++){
        formdata.append(id.toString(), this.uploadedFiles[i], this.uploadedFiles[i].name);
      }
      this._clienteService.uploadFile(formdata).then((res)=>{
        this.loading.onDoneRequest();
        let selectedReact = this.preguntas.find(x=>x.ReactivoItemEmpresaID == id);
        selectedReact.Respuesta = res[0];
      });
    }
  }

  descargar(id:number){
    window.open(GLOBAL.urlIMG + '/General/DownloadPdf?documentoId=' + id);
  }

  verDoc(id:string){
    window.open(id);
  }

  public ticket(flag:boolean, id:number){
    if(flag){
      this.asistenciaREQ.AsistenciaID = 0;
      this.asistenciaREQ.ReactivoItemEmpresaID = id;
      this.asistenciaREQ.Status = 1;
      this.asistenciaREQ.UsuarioID = this.identity.UsuarioID;
      this.asistenciaREQ.Comentario = "";
      this.mdlTicket = flag;
    }else{
      this.mdlTicket = flag;
    }
  }

  public crearTicket(){
    this.loading.onLoadingRequest();
    this._asistService.updateAsistencia(this.asistenciaREQ)
    .then(response=>{
      this.loading.onDoneRequest();
      if(response.status != 200){
        Swal.fire('Error', response.message, 'error');
      }else{
        Swal.fire('Éxito', response.message, 'success')
            .then(rs=>{
              this.mdlTicket = false;
            });
      }    
    }).catch(error=>{   
      this.loading.onDoneRequest();   
      Swal.fire('Error de conexión con el servidor', error.toString(), 'error');
    });
  }
  
  public aplica(id:number, aplica:boolean){
    let selectedReact = this.preguntas.find(x=>x.ReactivoItemEmpresaID == id);
    selectedReact.NoAplica = aplica;
  }
}
