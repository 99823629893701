import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ClientesService } from 'src/app/services/clientes/clientes.service';
import { UsuariosService } from 'src/app/services/usuarios/usuarios.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingEfectComponent } from 'src/app/Layout/loading-efect/loading-efect.component';

@Component({
  selector: 'app-alertas-tarifas',
  templateUrl: './alertas-tarifas.component.html',
  styleUrls: ['./alertas-tarifas.component.css']
})
export class AlertasTarifasComponent implements OnInit {
  loading = new LoadingEfectComponent();
  searchText;
  public catalog:any[];

  identity = JSON.parse(localStorage.getItem('identity_user'));

  constructor(
    private _service:ClientesService,
    private _serviceUsuarios:UsuariosService,
    public router: Router,
  ) { }

  ngOnInit(){
    this.getRegistros(true);
    this.registrarLog();
  }

  public registrarLog(){
    let clase = 'Alertas Tarifas';
    let obj = {
      //Controller: obj.Controller,
      Class: clase,
      //Table: obj.Table,
      //ID: obj.ID,
      Message: 'ACCESO'
    }
    this._serviceUsuarios.registrarLog(obj);
  }

  public getRegistros(pendientes:Boolean){
    this.loading.onLoadingRequest();
    this._service.getTarifasAlertas(pendientes)
    .then(response=>{
      this.loading.onDoneRequest();
      if(response.status == 200){
        this.catalog = response.content;
      }else if(response.status == 500){
        Swal.fire("Error",response.message,"error");
      }
    })
    .catch(error=>{
      this.loading.onDoneRequest();
      console.log(error.toString());
    });
  }

  public goTarifas(id:number){
    this.router.navigate(['/sa-rm/admin-client/' + id + '/tarifa/' + id]);
  }
}
