import { Component, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { AsistenciaService } from 'src/app/services/asistencia/asistencia.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { LoadingEfectComponent } from 'src/app/Layout/loading-efect/loading-efect.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-asistencia',
  templateUrl: './asistencia.component.html',
  styleUrls: ['./asistencia.component.css']
})
export class AsistenciaComponent implements OnInit {
  loading = new LoadingEfectComponent();

  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  dtOptions:any = {
    columnDefs:[
      {
        targets: 7,
        render: function(data, type) {
          var dt=new Date(data);
          var dia = ("0" + dt.getDate()).slice(-2)
          var mes = ("0" + (dt.getMonth() + 1)).slice(-2)
          return type == 'display' ? dia + "/" + mes + "/" + dt.getFullYear() : data
        }
      }
    ],
    dom: 'Bflrtip',
    buttons: [{
        extend:'excel',
        exportOptions: {
          columns: [0,1,2,3,4,5,6,7]
        }
      }
    ],
    language: {
        "decimal": "",
        "emptyTable": "No hay información",
        "info": "Mostrando _START_ a _END_ de _TOTAL_ Resultados",
        "infoEmpty": "Mostrando 0 to 0 of 0 Resultados",
        "infoFiltered": "(Filtrado de _MAX_ total resultados)",
        "infoPostFix": "",
        "thousands": ",",
        "lengthMenu": "Mostrar _MENU_ Resultados",
        "loadingRecords": "Cargando...",
        "processing": "Procesando...",
        "search": "Buscador:",
        "zeroRecords": "Sin resultados encontrados",
        "paginate": {
            "first": "Primero",
            "last": "Ultimo",
            "next": "Siguiente",
            "previous": "Anterior"
        }
    }
  };
  dtTrigger = new Subject();
  
  public empresaID:number = 0;
  public catalog:any[];
  public asistencia:any = {};
  //Variables para Flujo Tickets
  public mdlTicket: boolean = false;
  public asistenciaREQ: any = {};

  identity = JSON.parse(localStorage.getItem('identity_user'));

  constructor(
    private _service:AsistenciaService,
    public router: Router,
  ) { 
    if(this.identity.empresa){
      this.empresaID = this.identity.empresa.EmpresaID
    }
  }

  ngOnInit(){
    this.getRegistros(1);
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  public getRegistros(status:number){
    this.dtOptions.destroy;
    this.loading.onLoadingRequest();
    this._service.getAsistenciaList(this.empresaID, status)
    .then(response=>{
      this.loading.onDoneRequest();
      if(response.status == 200){
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          this.catalog = response.content;
          // Destroy the table first
          dtInstance.destroy();
          this.dtTrigger.next();
          this.loading.onDoneRequest();
        });
      }else if(response.status == 500){
        Swal.fire("Error",response.message,"error");
      }
    })
    .catch(error=>{
      this.loading.onDoneRequest();
      console.log(error.toString());
    });
  }

  public modalTicket(flag:boolean, id:number){
    if(flag){
      this.asistenciaREQ.AsistenciaID = id;
      this.asistenciaREQ.ReactivoItemEmpresaID = 0;
      this.asistenciaREQ.UsuarioID = this.identity.UsuarioID;
      this.asistenciaREQ.Comentario = "";

      this.loading.onLoadingRequest();
      this._service.getAsistencia(id)
      .then(response=>{
        this.loading.onDoneRequest();
        if(response.status == 200){
          if(response.content != null){
            this.asistencia = response.content;
            console.log(this.asistencia);
            this.mdlTicket = flag;
          }else{
            Swal.fire("Sin registros","Aún no se han registrado tickets","info");      
          }
        }else if(response.status == 500){
          Swal.fire("Error",response.message,"error");
        }
      })
      .catch(error=>{
        this.loading.onDoneRequest();
        console.log(error.toString());
      });
    }else{
      this.mdlTicket = flag;

      //Quitar miniatura en caso de que exista
      this._service.updateAsistenciaMin(false, this.asistenciaREQ.AsistenciaID)
      .then(response=>{
        this.loading.onDoneRequest();
        if(response.status == 200){
          console.log(response);
        }else if(response.status == 500){
          Swal.fire("Error",response.message,"error");
        }
      })
      .catch(error=>{
        console.log(error.toString());
      });
    }
  }

  public minTicket(flag:boolean){
    this._service.updateAsistenciaMin(flag, this.asistenciaREQ.AsistenciaID)
    .then(response=>{
      if(response.status == 200){
        console.log(response);
        this.mdlTicket = false;
      }else if(response.status == 500){
        Swal.fire("Error",response.message,"error");
      }
    })
    .catch(error=>{
      console.log(error.toString());
    });
  }

  public crearTicket(status:number){
    this.asistenciaREQ.Status = status;
    this.loading.onLoadingRequest();
    this._service.updateAsistencia(this.asistenciaREQ)
    .then(response=>{
      this.loading.onDoneRequest();
      if(response.status != 200){
        Swal.fire('Error', response.message, 'error');
      }else{
        Swal.fire('Éxito', response.message, 'success')
            .then(rs=>{
              this.mdlTicket = false;
            });
      }    
    }).catch(error=>{   
      this.loading.onDoneRequest();   
      Swal.fire('Error de conexión con el servidor', error.toString(), 'error');
    });
  }

  public openPreviewItem(id:number){
    this.router.navigate(['/sa-rm/itemreview/' + id]);
  }
}
