import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ClientesService } from 'src/app/services/clientes/clientes.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ActivatedRoute, Router } from '@angular/router';
import { ItemService } from 'src/app/services/items/item.service';
import { UsuariosService } from 'src/app/services/usuarios/usuarios.service';
import { LoadingEfectComponent } from 'src/app/Layout/loading-efect/loading-efect.component';

@Component({
  selector: 'app-itemlist-co',
  templateUrl: './itemlist-co.component.html',
  styleUrls: ['./itemlist-co.component.css']
})
export class ItemlistCoComponent implements OnInit {
  loading = new LoadingEfectComponent();
  public id = +this._route.snapshot.paramMap.get('id').split(",")[0];
  public tipo = +this._route.snapshot.paramMap.get('id').split(",")[1];
  public cuestionario: any = [];
  public item: any = {};
  public client: any = {};
  public titleAlerta:string = "";
  searchText;
  
  identity = JSON.parse(localStorage.getItem('identity_user'));

  constructor(
    private _serviceUsuario:UsuariosService,
    private _service:ClientesService,
    private _serviceItem:ItemService,
    private _route: ActivatedRoute,
    private _router: Router
    ) { }

  ngOnInit(): void {
    if(this.tipo == 4){
      this.titleAlerta = "Pendientes";
    }
    else if(this.tipo == 5){
      this.titleAlerta = "Por Caducar";
    }
    else if(this.tipo == 6){
      this.titleAlerta = "Caducados";
    }
    this.verifyClaims();
    this.getCliente();
    this.getItems();
  }

  verifyClaims(){
    var claim = this.identity.permisoLists.find(x => x.nombrePermiso === 'Empresas');
    if(claim == undefined && claim == null){
      this._router.navigate(['/sa-rm/admin']);
    }
  }

  public registrarLog(){
    let clase = 'Alertas ' + this.titleAlerta + ' > ' + this.client.Nombre;
    let obj = {
      //Controller: obj.Controller,
      Class: clase,
      //Table: obj.Table,
      //ID: obj.ID,
      Message: 'ACCESO'
    }
    this._serviceUsuario.registrarLog(obj);
  }

  public getCliente(){
    this._service.getCliente(this.id).then(response=>{
      this.client = response.content[0]; 
      if(this.client.statusacceso){
        $("#acceso").prop('checked', true);
      }
      this.registrarLog();
    });
  }

  public getItems(){    
    this._service.getItemAlerta(this.id, this.tipo).then(response=>{     
      this.cuestionario = response.content;
    })
    .catch(error => {      
      console.log(error.toString());
    });
  }

  public openPreviewItem(id:number){
    this._router.navigate(['/sa-rm/alertareactco/' + id + ',' + this.id + ',' + this.tipo]);
  }

  regresar(){
    if(this.tipo == 6){
      this._router.navigate(["/sa-rm/admin/alertas/3"]);
    }
    else if(this.tipo == 5){
      this._router.navigate(["/sa-rm/admin/alertas/2"]);
    }
    else if(this.tipo == 4){
      this._router.navigate(["/sa-rm/admin/alertas/4"]);
    }
  }
}
